import './App.css';
import React, {useContext, useEffect, useState} from "react";
import {useReadContract, useWalletClient} from "wagmi";
import {nftContract} from "./models/abi";
import {Address} from "./models/types";
import {ToastContainer, toast} from "react-toastify";
import ErrorModal from "./Error";
import Poll from './Poll/Poll'

import {ConnectKitButton} from "connectkit";
import {UserContext} from "./UserContext";
import {useSignMessage} from "wagmi";
import { showThrottleMessage } from 'ethers';

type Props ={
  name: string | null;
}
function App({name} : Props) {
  const [lastToast, setLastToast] = useState(null as string | null);
  const [lastToastString, setLastToastString] = useState(null as string | null);
  const [isConnected, setIsConnected] = useState(false);
  const [address, setAddress] = useState<string | undefined>(undefined);
  const {ctxAddress, ctxSetAddress, ctxIsLoading, ctxSetIsLoading, ctxToken, ctxSetToken, ctxMessage, 
        ctxSignedMessage, ctxSetSignedMessage, ctxError, ctxSetError, ctxLastError, ctxSetLastError, 
        ctxIsParamCorrect, ctxSurveyState, ctxSetSubmitRefetch, ctxSetSelectedAnswer, ctxSelectedAnswer,
        ctxSetDeadEnd, ctxDeadEnd } = useContext(UserContext);
  const {data: walletClient} = useWalletClient();

  toast.bind(<ToastContainer
    position="top-right"
    autoClose={5000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    limit={1}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    theme="colored"
  />)
  const responseDataNft = useReadContract({
    ...nftContract,
    functionName: "getNFTInfo",
    args: [ctxAddress || "0x0000000000000000000000000000000000000000"],
    query: {},
  });

  if (
    ctxAddress &&
    responseDataNft.data !== null &&
    responseDataNft.data !== undefined &&
    ((responseDataNft.data[0] > 0 && ctxToken !== responseDataNft.data[1]) || (responseDataNft.data[1] <= 0 && ctxToken !== BigInt(-1)))
  ) {
    if (responseDataNft.data[1] <= 0) {
      ctxSetToken(BigInt(-1));
      ctxSetError('Click the button below to logout');
      ctxSetDeadEnd('Click the button below to logout');
    } else {
      ctxSetToken(responseDataNft.data[0]);
    }
  }
  
  if (
    ctxAddress !== walletClient?.account.address.toLowerCase()
  ) {
    ctxSetAddress(walletClient?.account.address.toLowerCase() as Address | null);
  }

  useEffect(() => {
    ctxSetAddress(address ? `0x${address}` : null);
    if (isConnected &&  walletClient?.account.address &&
      ctxAddress !== walletClient?.account.address) {
    }
  }, [isConnected]);
  const { signMessage, isSuccess, isError, isPending, data:signedMessage, reset } = useSignMessage();

  if (ctxAddress != null && !isSuccess && !isError && !isPending && ctxToken != null && ctxMessage != null && ctxSignedMessage == null && ctxIsParamCorrect ==null) {
    signMessage({ message: { raw: ctxMessage as `0x${string}`}, account: ctxAddress});
    ctxSetIsLoading(true);
  }
  if (isSuccess && !!signedMessage && ctxSignedMessage != signedMessage){
    ctxSetSignedMessage(signedMessage);
    ctxSetIsLoading(true);
  }
  if (isError && ctxError != "Signing Request Failed" && (ctxLastError != ctxError || ctxError == null ) ) {
    ctxSetError("Signing Request Failed");
      if( ctxIsLoading){
        ctxSetIsLoading(false);
      }
    }

  const handleClick = ({ show, isConnected, address }: { show: () => void; isConnected: boolean; address: string | undefined }) => {
    if (ctxAddress !== `${walletClient?.account.address.toLowerCase()}` || ctxAddress == null || !isConnected ){
      ctxSetAddress(address ? `0x${walletClient?.account.address}` : null);
      ctxSetIsLoading(true);
      show();
    } else {
      reset();
      ctxSetError(null);
      signMessage({ message: { raw: ctxMessage as `0x${string}`}, account: ctxAddress});
      ctxSetIsLoading(true);
    }
  };

  const handleVote = (answerValue : any) => {
    let selectedObj = ctxSurveyState.answers.find(a => a.answer == answerValue);
    if(selectedObj.id != ctxSelectedAnswer){
      ctxSetSelectedAnswer(selectedObj.id);
    }
  };
  const handleSubmit = () => {
    ctxSetSubmitRefetch();
    ctxSetIsLoading(true);
  };
  if (ctxError) {
    var id = lastToast;
    if (ctxError == "Signing Request Failed" && lastToastString != "Signing Request Failed"){
      id = (toast.error("Signing Request Failed", {toastId: "Signing Request Failed"})).toString();
      setLastToast(id);
      setLastToastString("Signing Request Failed");
    } else if (ctxError == "Message Request Failed" && ctxError != lastToastString && lastToastString != "Message Request Failed"){
      id = (toast.error("Message Request Failed", {toastId: "Message Request Failed"})).toString();
      setLastToast(id);
      setLastToastString("Message Request Failed");
    } else if (ctxError == "Authentication Request Failed" && ctxError != lastToastString && lastToastString != "Authentication Request Failed"){
      id = (toast.error("Authentication Request Failed", {toastId: "Authentication Request Failed"})).toString();
      setLastToast(id);
      setLastToastString("Authentication Request Failed");
    }else if (ctxError == "Poll not found" && lastToastString != "Poll not found"){
      id = (toast.error("Poll not found", {toastId: "Poll not found"})).toString();
      setLastToast(id);
      setLastToastString("Poll not found");
    } else if (ctxError == "Submit Request Failed" && lastToastString != "Submit Request Failed"){
      id = (toast.error("Submit Request Failed", {toastId: "Submit Request Failed"})).toString();
      setLastToast(id);
      setLastToastString("Submit Request Failed");
    } else if (ctxError == "Api Error" && lastToastString != "Unknown Server Error"){
      id = (toast.error("Unknown Server Error", {toastId: "Unknown Server Error"})).toString();
      setLastToast(id);
      setLastToastString("Unknown Server Error");
    }
  }
  return (
    <>
      <div className='app-body'>
        <div className="effect">
          <img src="/assets/effect.png" alt="vector" />
        </div>

        {ctxIsLoading?<div className="logoIntro">
          <img src="/assets/logoDentroNew.png" alt="logo" />
          <img src="/assets/logoForaNew.png" alt="logo" />
        </div>:<></>}
        {!ctxIsLoading?
        <main>
          <div className="logo">
            <img src="/assets/login.png" alt="login" />
          </div>
          
          {
          ctxSurveyState == null || ctxDeadEnd !== null ?   
            (<div className="textMain"><h1>Join<br></br>
              MegaMaker
              Voting.</h1>
            <p>Be part of the largest community of market makers.</p>
            </div>):
            (<div>

              <div style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>

              <ConnectKitButton.Custom>
                  {({ show, isConnected, address }) => {
                    if (isConnected){
                      return (
                      <div className='flex'><ConnectKitButton theme="midnight" customTheme={"connect-button"} onClick={
                        (show)=>{
                          reset();
                          show();
                        }
                      }/>
                      </div>)
                    }
                    return (<></>)
                  }}
                </ConnectKitButton.Custom>
                
              </div>
              
               <Poll customStyles={{theme: 'white', questionColor: 'white', align:'center'}} vote={ctxSurveyState?.answers.find(a => a.id == ctxSurveyState.walletVote.answerId)?.answer ?? 'f'} question={ctxSurveyState.question} answers={ctxSurveyState.answers.map(a => { return {option: a.answer, votes: Number(a.votePercent.toFixed(2))}})} noStorage={true} 
               onVote={(a)=>{
                handleVote(a);
               }} isDone={ctxSurveyState.walletVote.answerId != null || true}/>
            </div>)
          }
            
          <div className='inputContainer'>
            {
              (ctxDeadEnd !== null?
                <button className="btnConnectError" id="disabled" disabled>
                    {ctxDeadEnd}
                </button>
                :
            ctxSurveyState != null?
            (<button className="btnConnect" id="disabled" disabled>
                  Voting Closed
              </button>)
            :
                (ctxAddress == null ?<ConnectKitButton.Custom>
                  {({ show, isConnected, address }) => (
                    <button className="btnConnect" id="show-toast-success" onClick={() => handleClick({ show: show || (() => {}), isConnected, address })}  >Connect
                      Wallet
                  </button>)}
                </ConnectKitButton.Custom>:
                <ConnectKitButton.Custom>
                  {({ show, isConnected, address }) => (
                    <button className="btnConnect" id="show-toast-success" onClick={() => handleClick({ show: show || (() => {}), isConnected, address })}  >
                      Authenticate
                  </button>)}
                </ConnectKitButton.Custom>)
              )
            }
            {ctxAddress !== null? 
            
              <div
              className="connect-button"
              style={{display: `flex`, marginTop:'8px',justifyContent:'center'}}
            >
                <ConnectKitButton.Custom>
                  {({ show, isConnected, address }) => {
                    if (isConnected){
                      return (
                      <div className='btnConnectWarning' onClick={ (e)=>{
                          reset();
                          show();
                        }}>Logout</div>)
                    }
                    return (<></>)
                  }}
                </ConnectKitButton.Custom>
            </div>
            : <></>}
          
          </div>
          <footer className="footer">
            <img src="/assets/logoMega.png" alt="MEGA MAKER"/>
          </footer>
        </main>:<></>}
      </div>
        { ctxError != null ? <ErrorModal lastErrorMessage={lastToastString ?? ""} errorMessage={ctxError ?? ""} /> : <></>}
      </>
  );
}

export default App;
