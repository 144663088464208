import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './Poll.css'
import 'animate.css';

const themes = {
  purple: ['#6D4B94', '#7C6497', '#6D4B943B'],
  red: ['#E23D3D', '#EF4545', '#FF28283B'],
  blue: ['#5674E0', '#5674E0', '#5674E03B'],
  black: ['#303030', '#303030', '#3030303B'],
  white: ['#ffffff', '#ffffff', '#ffffff3B'],
  cyan: ['#00BCDD', '#00BCDD', '#00BCDD3B']
}

type PollProps = {
    question: string,
    answers: any[],
    onVote: Function,
    totalVotes: null | number,
    customStyles: any,
    noStorage: boolean,
    vote: string,
    isDone: boolean,
}

export default class Poll extends Component {
  // Answers prop format: [ { option: string, votes: number } ]
  static propTypes = {
    question: PropTypes.string.isRequired,
    answers: PropTypes.array.isRequired,
    onVote: PropTypes.func.isRequired,
    customStyles: PropTypes.object,
    noStorage: PropTypes.bool,
    vote: PropTypes.string,
    isDone: PropTypes.bool,
  }

  static defaultProps = {
    customStyles: {
      questionSeparator: true,
      questionSeparatorWidth: 'question',
      questionBold: true,
      questionColor: '#303030',
      align: 'center',
      theme: 'black'
    },
    noStorage: false
  }

  state = {
    poll: {
      voted: false,
      option: '',
      ended: false,
    },
    totalVotes: 0
  }

  componentDidMount() {
    this.loadVotes()
  }

  componentWillReceiveProps() {
    this.loadVotes()
  }

  loadVotes = () => {
    const { answers, vote, totalVotes } = this.props as PollProps
    this.setState({
      totalVotes: totalVotes
    })
    if (vote) this.setPollVote(vote)
  }

  setPollVote = (answer) => {
    const { answers, vote } = this.props as PollProps
    const optionsOnly = answers.map(item => item.option)

    if (optionsOnly.includes(answer)) {
      const { poll, totalVotes } = this.state
      const newPoll = { ...poll }
      newPoll.voted = true
      newPoll.option = answer

      if (!vote) {
        this.setState({
          poll: newPoll,
        })
      } else {
        this.setState({
          poll: newPoll
        })
      }
    }
  }


  vote = answer => {
    const { question, onVote } = this.props as PollProps

    this.setPollVote(answer)
    onVote(answer)
  }

  calculatePercent = (votes) => {
    if (votes === 0) {
      return '0%'
    }
    return `${(votes).toFixed(2)}%`
  }

  alignPoll = (customAlign) => {
    if (customAlign === 'left') {
      return 'flex-start'
    } else if (customAlign === 'right') {
      return 'flex-end'
    } else {
      return 'center'
    }
  }

  obtainColors = customTheme => {
    const colors = themes[customTheme]
    if (!colors) {
      return themes['white']
    }
    return colors
  }

  render() {
    const { question, answers, customStyles, isDone } = this.props as PollProps
    const { poll, totalVotes } = this.state
    const colors = this.obtainColors(customStyles.theme)

    return (
      <article className={`${'animate__animated'} ${'animate__fadeIn'} ${'animate__faster'} poll`} style={{ textAlign: customStyles.align, alignItems: this.alignPoll(customStyles.align) }}>
        <h3 className={'question'} style={{ borderWidth: customStyles.questionSeparator ? '1px' : '0', alignSelf: customStyles.questionSeparatorWidth === 'question' ? 'center' : 'stretch', fontWeight: customStyles.questionBold ? 'bold' : 'normal', color: customStyles.questionColor }}>{question}</h3>
        <ul className={'answers'}>
          {answers.map(answer => (
            <li key={answer.option}>
              {!isDone ? (
                <button className={`${'animate__animated'} ${'animate__fadeIn'} ${'animate__faster'} ${'white'} ${answer.option === poll.option ? 'vote result' : 'option'}`} style={{ color: colors[0], borderColor: colors[1] }} type='button' onClick={() => this.vote(answer.option)}>
                  {answer.option !== poll.option ? answer.option:
                  <>
                        <span className={`${'answer'} ${answer.option === poll.option ? 'vote' : ''}`} style={{ color: colors[0] }}>{answer.option}</span>
                        </>}
                </button>
              ) : (
                <div className={`${'animate__animated'} ${'animate__fadeIn'} ${'animate__faster'} ${'result'}`} style={{ color: colors[0], borderColor: colors[1] }}>
                  <div className={'fill'} style={{ width: this.calculatePercent(answer.votes), backgroundColor: colors[2] }} />
                  <div className={'labels'}>
                    <span className={'percent'} style={{ color: colors[0] }}>{this.calculatePercent(answer.votes)}</span>
                    <span className={`${'answer'} ${answer.option === poll.option ? 'vote' : ''}`} style={{ color: colors[0] }}>{answer.option}</span>
                  </div>
                </div>
              )}
            </li>
          ))}
        </ul>
        {/* <p className="votes">{`${totalVotes} vote${totalVotes !== 1 ? 's' : ''}`}</p> */}

      </article>
    )
  }
}