import { Types } from "connectkit";

const MegaMakerAvatar = (token:number) => ({ address, ensImage, ensName, size, radius }: Types.CustomAvatarProps) => {
  let tokenImage;
  switch(token) {
    case 1:
      tokenImage = "./assets/1.jpeg";
      break;
    case 2:
      tokenImage = "./assets/2.jpeg";
      break;
    case 3:
      tokenImage = "./assets/3.jpeg";
      break;
    case 4:
      tokenImage = "./assets/4.jpeg";
      break;
    case 5:
      tokenImage = "./assets/5.jpeg";
      break;
    case 6:
      tokenImage = "./assets/6.jpeg";
      break;
    case 7:
      tokenImage = "./assets/7.jpeg";
      break;
    default:
      tokenImage = "./assets/ftkx.png";
  }
  return (
    <div
      style={{
        overflow: "hidden",
        borderRadius: radius,
        height: size,
        width: size,
        background: tokenImage,
      }}
    >
      {tokenImage && <img src={tokenImage} alt={ensName ?? address} width="100%" height="100%" />}
    </div>
  );
};

export default MegaMakerAvatar;
