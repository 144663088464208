export const getNftInfoABI = [
    {
      type: 'function',
      name: 'getNFTInfo',
      stateMutability: 'view',
      inputs: [{ name: 'account', type: 'address' }],
      outputs: [{ type: 'uint256' }, {type: 'uint256'}],
    }
  ] as const
  
  export const nftContract = {
    address: '0xcD0F8b7f1004E09A68ac0639A82Ff9423623043A',
    // address: '0x9fE46736679d2D9a65F0992F2272dE9f3c7fa6e0',
    abi: getNftInfoABI
  } as const